import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Stage from 'ui-kit/stage';
import Section from 'ui-kit/section';
import Intro from 'ui-kit/intro';
import Container from 'ui-kit/container';
import Image from 'ui-kit/image';
import Joblist from 'ui-kit/joblist';
import { P, Headline, Highlight } from 'ui-kit/typography';
import Accordion from 'ui-kit/accordion';
import Button from 'ui-kit/button';
import List from 'ui-kit/list';
import StickyNav from 'ui-kit/sticky-nav';
import Observable from 'ui-kit/observable';

import Layout from '../../components/layout';
import TeaserSection from '../../components/teaser-section';

/** Jobs */
const jobs = [
    {
        title: 'Auszubildender zum Kaufmann für Bürokommunikation (m/w)',
        id: 'bar',
    },
    {
        title: 'Auszubildender zum Kaufmann für Groß- und Außenhandel (m/w)',
        id: 'bar',
    },
    {
        title: 'Metzgermeister (m/w)',
        id: 'bar',
    },
    {
        title: 'Produktionsmitarbeiter (m/w)',
        id: 'bar',
    },
];

const JobOffersAndApprenticeshipPage = ({ data }) => {
    /** Stage-Hintergrund */
    const stageImage = data.stageImage.childImageSharp;
    const apprenticeshipImage = data.apprenticeshipImage.childImageSharp;
    const apprenticeshipTrades = data.apprenticeshipTrades.edges;

    /** Inhalte des Accordions */
    const accordionItems = apprenticeshipTrades.map(({ node: apprenticeshipTrade }) => ({
        title: apprenticeshipTrade.title,
        content: (
            <>
                <Container grid cols="2" layout="3-5" gap="l" alignItems="start">
                    <Image {...apprenticeshipTrade.image.childImageSharp} layer={1} />
                    <div>
                        <Headline level="h5" gap="m">
                            Was macht man da aus sich?
                        </Headline>
                        <P gap="l">{apprenticeshipTrade.perspective}</P>
                    </div>
                </Container>
                <hr />
                <Headline level="h5" gap="m">
                    Aufgaben in Kürze:
                </Headline>
                <List showBullets items={apprenticeshipTrade.perspectiveSummary} gap="l" />
                <Headline level="h5">Was lernt man da genau?</Headline>
                <List showBullets items={apprenticeshipTrade.learnings} gap="l" />
                <Headline level="h5">Was sollte man dafür mitbringen?</Headline>
                <List showBullets items={apprenticeshipTrade.requirements} gap="l" />
                <Headline level="h5">Dauer und Ablauf</Headline>
                <P gap="xl">{apprenticeshipTrade.durationAndProcedure}</P>
                <Button linkTo={apprenticeshipTrade.detailLink}>Zum Ausbildungsberuf</Button>
            </>
        ),
    }));

    return (
        <Layout description="Stellenangebote">
            <Stage
                image={stageImage}
                tag="Karriere | Ausbildungsberufe & Stellangebote"
                headline={{ text: 'Ausbildungsberufe & Stellenangebote', level: 'h1' }}
                imageAlt="Wanderer blickt auf Berggipfel"
                bottomShade
            />
            <Section>
                <Intro title="Stellen Sie jetzt die Weichen für Ihre berufliche Zukunft">
                    Werden Sie Teil eines aufstrebenden und erfolgreichen Unternehmen der
                    Lebensmittelbranche. Werden Sie Teil unseres starken Teams und freuen Sie sich
                    auf interessante, abwechslungsreiche Tätigkeiten sowie hervorragende
                    Entwicklungs- und Aufstiegsmöglichkeiten. Hier finden Sie alles Wichtige zu
                    unseren Ausbildungsberufen und aktuelle Stellenangebote.
                </Intro>
            </Section>
            <StickyNav
                items={[
                    {
                        id: 'ausbildung',
                        text: 'Ausbildung bei uns',
                        to: '#ausbildung',
                    },
                    {
                        id: 'stellenangebote',
                        text: 'Stellenangebote',
                        to: '#stellenangebote',
                    },
                ]}
            >
                {({ setActivePageFragment }) => (
                    <>
                        <Section bg="gray" id="ausbildung">
                            <Observable callback={() => setActivePageFragment('ausbildung')}>
                                <Headline level="h2" textAlign="center" gap="xl">
                                    Ausbildung bei uns
                                </Headline>
                                <Container
                                    size="l"
                                    gap="xxxxl"
                                    grid
                                    layout="6-6"
                                    cols="2"
                                    alignItems="center"
                                >
                                    <Image
                                        {...apprenticeshipImage}
                                        layer={1}
                                        gap={{ small: 'xxl', medium: 'none' }}
                                        alt="Vier unterschiedliche Auszubildene in selbstbewussten Posen"
                                    />
                                    <div>
                                        <P>
                                            Du hast deine Schulzeit erfolgreich beendet und stehst
                                            vor dem Start ins Berufsleben? Mit einer Ausbildung bei
                                            der Südbayerischen Fleischwaren GmbH legst du den
                                            Grundstein für deine Karriere. Denn was für unsere
                                            Erzeugnisse gilt, das gilt auch für unsere Ausbildung:
                                            Wir stellen höchste Qualitätsansprüche. Deshalb hat uns
                                            die IHK München/Oberbayern als Ausbildungsbetrieb 2018
                                            ausgezeichnet.
                                        </P>
                                    </div>
                                </Container>
                                <Container size="l">
                                    <Headline level="h3" gap="xl">
                                        Informiere dich hier über unsere Ausbildungsberufe
                                    </Headline>
                                    <Accordion scrollToOffset={80} items={accordionItems} />
                                </Container>
                            </Observable>
                        </Section>
                        <Section id="stellenangebote">
                            <Observable callback={() => setActivePageFragment('stellenangebote')}>
                                <Intro title="Aktuelle Stellenangebote" gap="xxl">
                                    <P>
                                        Unser Unternehmen wächst und entwickelt sich kontinuierlich
                                        weiter. Deshalb suchen wir Verstärkung: Teamplayer, die sich
                                        – wie wir – für qualitativ hochwertige Lebensmittel
                                        begeistern und uns auf dem Weg in eine noch erfolgreichere
                                        Zukunft begleiten.
                                    </P>
                                    <Highlight>Vielleicht genau Sie!</Highlight>
                                </Intro>
                                <Container size="l">
                                    <Joblist items={jobs} showFilter />
                                </Container>
                            </Observable>
                        </Section>
                    </>
                )}
            </StickyNav>
            <TeaserSection bg="gray" />
        </Layout>
    );
};

export const query = graphql`
    query {
        stageImage: file(relativePath: { eq: "career-page/buehne-karriere.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        apprenticeshipImage: file(
            relativePath: { eq: "teaserboxes/teaserbox-ausbildungsberufe.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 605, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        apprenticeshipTrades: allApprenticeshipTradesJson {
            edges {
                node {
                    title
                    image {
                        childImageSharp {
                            fluid(maxWidth: 500, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    perspective
                    perspectiveSummary
                    learnings
                    requirements
                    durationAndProcedure
                    detailLink
                }
            }
        }
    }
`;

JobOffersAndApprenticeshipPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
        apprenticeshipImage: PropTypes.object,
        apprenticeshipTrades: PropTypes.object,
    }).isRequired,
};

export default JobOffersAndApprenticeshipPage;
